import { useAuthStore } from '~/store/auth'

export default function AddToken ({ request, options }: any) {
    const auth = useAuthStore();

    if (auth.user.token) {
        options.headers = options.headers || {}
        options.headers.authorization = `Bearer ${auth.user.token}`;
    }
}
