import { useAuthStore } from '~/store/auth';
import AddToken from './plugin';
import { useModalStore } from '~/store/modal';

export default async function ApiCall (url: string, method:any, body: any = null, params: any = null, token: boolean = true) :Promise<any> {
    const authStore = useAuthStore();
    const modalStore = useModalStore();
    const router = useRouter();
    const route = useRoute();
    if (token && !authStore.user.token) {
        if (route.name == 'profile') {
            router.push({ path: '/' });
        }
        modalStore.openLoginModal();
        return;
    }
    return await $fetch(url, {
        baseURL: import.meta.env.VITE_APP_API_URL as string,
        method: method,
        body: body ? body : undefined,
        params: params ? params : undefined,
        onRequest: token ? AddToken : undefined,
        onResponse: async ({ request, response, options }: any) => {
            if (response.status === 401) {
                authStore.purgeAuth();
                modalStore.openLoginModal();
                return;
            }
        }
    })
}
