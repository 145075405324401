import { ref, computed } from 'vue';
import { defineStore, skipHydrate } from 'pinia';
import { api } from '~/http/Api';
import { useStorage } from '@vueuse/core';

export interface User {
  id: string;
  name: string;
  email: string;
  token: string;
  mobile_number: string;
  gender: string;
  address: string;
  dob: string;
  aadhar_no: string;
}

export const useAuthStore = defineStore('auth', () => {
  const errors = ref({});
  const user = useStorage(
    'user',
    ref<User>({
      id: '',
      name: '',
      email: '',
      token: '',
      mobile_number: '',
      gender: '',
      address: '',
      dob: '',
      aadhar_no: '',
    }),
  );
const passDetails = ref();
  const userEmptyObj = {
    id: '',
    name: '',
    email: '',
    token: '',
    mobile_number: '',
    gender: '',
    address: '',
    dob: '',
    aadhar_no: '',
  };

  const isAuthenticated = computed(() => {
    return !!user.value.id;
  });

  function setAuth(authUser: User) {
    user.value = authUser;
    errors.value = {};
  }

  function setError(error: any) {
    errors.value = { ...error };
  }

  function purgeAuth() {
    user.value = userEmptyObj as User;
    errors.value = [];
  }

  async function login(credentials: any) {
    return await api.post('login', credentials, null, false);
  }

  async function verifyOTP(otp: any) {
    return await api.post('verify-otp', otp, null, false);
  }

  function logout() {
    purgeAuth();
    navigateTo('/');
  }

  async function register(credentials: any) {
    return await api.post('register', credentials, null, false);
  }

  return {
    errors,
    user,
    // user: skipHydrate(user),
    userEmptyObj,
    isAuthenticated,
    passDetails,
    login,
    logout,
    register,
    purgeAuth,
    verifyOTP,
    setAuth,
    setError
  };
}, { persist: true});
