import { defineStore } from "pinia";

export const useModalStore = defineStore('modal', () => {
    const isLoginModalOpen = ref(false);

    function openLoginModal() {
        isLoginModalOpen.value = true;
    }

    function closeLoginModal() {
        isLoginModalOpen.value = false;
    }

    return {
        isLoginModalOpen,
        openLoginModal,
        closeLoginModal
    }
}, { persist: true });
