export default {
  catchErrorMessage: "Oops! something went wrong.",
  tripType: {
    oneway: "oneway",
    round: "round",
    multi: "multi",
  },
  passType: [
    {
        label: 'Daily',
        value: 'Daily',
    },
    {
        label: 'Weekly',
        value: 'Weekly',
    },
    {
        label: 'Monthly',
        value: 'Monthly',
    },
  ],
  passTypeObj: {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
  },
  passCategory: [
    {
        label: 'Passenger',
        value: 'Passenger',
    },
    {
        label: 'Vehicle',
        value: 'Vehicle',
    },
  ],
  gender: [
    {
      label: 'Male',
      value: 'Male',
    },
    {
      label: 'Female',
      value: 'Female',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
  proof_type: [
    {
      label: 'Aadhar',
      value: 'aadhar',
    },
    // {
    //   label: 'Passport',
    //   value: 'Passport',
    // },
    {
      label: 'Driving Licence',
      value: 'driving_licence',
    },
    {
      label: 'Student Id',
      value: 'student_id',
    },
  ],

  countries: [
     "+91",
     "+1",
    "+44",
     "+61",
     "+49",
     "+55",
     "+81",
     "+52",
     "+39",
     "+34",
     "+86",
  ],
};
